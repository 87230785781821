<template>
  <!-- PROJECT CHANNELS BUDGETS MODAL -->
  <b-modal
    id="project-channels-budgets"
    size="xl"
    :hide-footer="!canEdit"
    :title="$t('table.budgets')"
    :cancel-title="$t('table.cancel')"
    :ok-title="$t('table.save')"
    :ok-disabled="$v.$invalid"
    @show="loadData()"
    @ok="checkIfValidThenEnter"
    @hidden="closedModal()"
  >
    <form @submit.stop.prevent="checkIfValidThenEnter">
      <input type="submit" value="Submit" class="hidden-submit" />
      <div class="table-responsive mb-0">
        <h5 class="d-inline-block mr-3 mb-0 align-middle">{{ $t('table.approvedCP') }}</h5>
        <table id="approved-budgets-table" class="table table-sm table-hover mt-2 mb-3 ws-nowrap small w-auto">
          <thead>
            <tr>
              <th style="min-width: 105px">{{ $t('channelModal.channel') }}</th>
              <th style="width: 75px">% {{ $t('channelModal.prime') }}</th>
              <th style="min-width: 108px">{{ $t('table.type') }}</th>
              <th v-for="(mn, mIndex) in months" :key="mIndex">{{ mn }}</th>
              <th colspan="2">{{ $t('booking.total') }}</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(sel, index) in channelsBudgets">
              <tr :key="index">
                <td :rowspan="getChannelRowSpan(index)">
                  <p class="mb-1">
                    <button type="button" class="btn-transparent mr-1 font-weight-bold" @click="toggleVisibility(sel, 'hiddenRows')">
                      {{ sel.hiddenRows ? '+' : '-' }} {{ sel.channel ? sel.channel.name : '' }}
                    </button>
                  </p>
                  <button
                    v-if="canEdit"
                    type="button"
                    class="btn btn-sm btn-outline-danger"
                    :disabled="isClosed"
                    data-automatic="add-type-btn"
                    @click="addTypeRow(sel)"
                  >
                    {{ $t('table.addType') }}
                  </button>
                </td>
                <td :rowspan="getChannelRowSpan(index)">
                  <input
                    v-model.number="sel.prime"
                    class="form-control form-control-sm form-control-prime"
                    type="number"
                    step="any"
                    min="0"
                    max="100"
                    data-automatic="input-mcb-prime"
                    :disabled="isClosed || !canEdit"
                  />
                </td>
                <td colspan="15" :class="[!sel.hiddenRows ? 'first-colspan-td' : '', 'text-left pl-2']">...</td>
              </tr>
              <template v-if="!sel.hiddenRows">
                <template v-for="(plType, pIndex) in sel.placementTypes">
                  <tr :key="`${index}_${pIndex}`">
                    <td>
                      <span :class="setPlacementClass(plType.type ? plType.type.id : undefined)"></span>
                      <select
                        v-model="plType.type"
                        class="custom-select custom-select-sm s-w-100"
                        data-automatic="select-mcb-type"
                        required
                        :disabled="isClosed || !canEdit"
                      >
                        <option disabled value=""></option>
                        <option v-for="(ch, i) in placementTypeWithoutAll" :key="i" :value="ch">{{ ch.name }}</option>
                      </select>
                    </td>
                    <td v-for="(month, mIndex) in plType.months" :key="`${index}_${pIndex}_${mIndex}`">
                      <currency-input
                        :id="`ci-a${index}_${pIndex}_${mIndex}`"
                        v-model="month.original_budget"
                        class="form-control-sm budget-input"
                        :disabled="isClosed || !canEdit"
                        @input="writeInCurrentCP(month, mIndex)"
                        @setLastUsedInput="setLastUsedInput(`ci-a${index}_${pIndex}_${mIndex}`)"
                      />
                    </td>
                    <th>{{ countBudgetRowTotal(index, pIndex, 'original_budget') | spaceNumber }}</th>
                    <td>
                      <button
                        v-if="canEdit"
                        type="button"
                        class="btn-transparent mx-1"
                        :disabled="isClosed"
                        data-automatic="add-type-btn"
                        @click="removeTypeRow(index, pIndex)"
                      >
                        <b-icon class="trash-hover cursor-pointer" icon="trash" :title="$t('table.delete')"></b-icon>
                      </button>
                    </td>
                  </tr>
                  <tr v-if="showEquals" :key="`${index}_${pIndex}_prime`">
                    <td>
                      <span :class="setPlacementClass(plType.type ? plType.type.id : undefined)"></span>
                      <label class="s-w-100-fit pr-1">
                        {{ $t('equalsWGRP.equalsPrime') }}
                      </label>
                    </td>
                    <td v-for="(month, mIndex) in plType.months" :key="`${index}_${pIndex}_${mIndex}`">
                      <currency-input
                        :id="`ci-b${index}_${pIndex}_${mIndex}`"
                        v-model="month.original_prime_wgrp"
                        class="form-control-sm budget-input"
                        :disabled="isClosed || !canEdit"
                        @input="writeInCurrentCP_EP(month, mIndex)"
                        @setLastUsedInput="setLastUsedInput(`ci-b${index}_${pIndex}_${mIndex}`)"
                      />
                    </td>
                    <th>{{ countBudgetRowTotal(index, pIndex, 'original_budget') | spaceNumber }}</th>
                    <td></td>
                  </tr>
                  <tr v-if="showEquals" :key="`${index}_${pIndex}_off-prime`">
                    <td>
                      <span :class="setPlacementClass(plType.type ? plType.type.id : undefined)"></span>
                      <label class="s-w-100-fit pr-1">
                        {{ $t('equalsWGRP.equalsOffPrime') }}
                      </label>
                    </td>
                    <td v-for="(month, mIndex) in plType.months" :key="`${index}_${pIndex}_${mIndex}_off-prime`">
                      <currency-input
                        :id="`ci-d${index}_${pIndex}_${mIndex}`"
                        v-model="month.original_off_prime_wgrp"
                        class="form-control-sm budget-input"
                        :disabled="isClosed || !canEdit"
                        @input="writeInCurrentCP_EOP(month, mIndex)"
                        @setLastUsedInput="setLastUsedInput(`ci-d${index}_${pIndex}_${mIndex}`)"
                      />
                    </td>
                    <th>{{ countBudgetRowTotal(index, pIndex, 'original_budget') | spaceNumber }}</th>
                    <td></td>
                  </tr>
                </template>

                <tr :key="`${index}-totals`" class="total-row">
                  <th>{{ $t('booking.total') }}</th>
                  <th v-for="(mn, mIndex) in months" :key="mIndex">{{ countBudgetColTotal(index, mIndex, 'original_budget') | spaceNumber }}</th>
                  <th>{{ countCornerSum(index, 'original_budget') | spaceNumber }}</th>
                  <th></th>
                </tr>
              </template>
            </template>
          </tbody>
        </table>

        <h5>{{ $t('table.currentCP') }}</h5>
        <table id="corrected-budgets-table" class="table table-sm table-hover mt-2 mb-3 ws-nowrap small w-auto">
          <thead>
            <tr>
              <th style="min-width: 105px">{{ $t('channelModal.channel') }}</th>
              <th style="width: 75px">% {{ $t('channelModal.prime') }}</th>
              <th style="min-width: 108px">{{ $t('table.type') }}</th>
              <th v-for="(mn, mIndex) in months" :key="mIndex">{{ mn }}</th>
              <th>{{ $t('booking.total') }}</th>
              <th>{{ $t('table.delta') }}</th>
              <th v-if="isLimitedBudgets">{{ $t('table.budgetExcess') }}, %</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(sel, index) in channelsBudgets">
              <tr :key="index">
                <td :rowspan="!sel.hiddenRowsCCP ? sel.placementTypes.length * (showEquals ? 4 : 2) + 2 : 1">
                  <button type="button" class="btn-transparent mr-1 font-weight-bold" @click="toggleVisibility(sel, 'hiddenRowsCCP')">
                    {{ sel.hiddenRowsCCP ? '+' : '-' }} {{ sel.channel ? sel.channel.name : '' }}
                  </button>
                </td>
                <td :rowspan="!sel.hiddenRowsCCP ? sel.placementTypes.length * (showEquals ? 4 : 2) + 2 : 1">
                  <input
                    v-model.number="sel.prime"
                    class="form-control form-control-sm form-control-prime"
                    type="number"
                    step="any"
                    min="0"
                    max="100"
                    data-automatic="input-mcb-prime-corrected"
                    disabled
                  />
                </td>
                <td :colspan="isLimitedBudgets ? 16 : 15" :class="[!sel.hiddenRowsCCP ? 'first-colspan-td' : '', 'text-left pl-2']">...</td>
              </tr>
              <template v-if="!sel.hiddenRowsCCP">
                <template v-for="(plType, pIndex) in sel.placementTypes">
                  <tr :key="`${index}_${pIndex}`">
                    <td>
                      <span :class="setPlacementClass(plType.type ? plType.type.id : undefined)"></span>
                      <select v-model="plType.type" class="custom-select custom-select-sm s-w-100" data-automatic="select-mcb-type-corrected" required disabled>
                        <option disabled value=""></option>
                        <option v-for="(ch, i) in placementTypeWithoutAll" :key="i" :value="ch">{{ ch.name }}</option>
                      </select>
                    </td>
                    <td v-for="(month, mIndex) in plType.months" :key="`${index}_${pIndex}_${mIndex}`">
                      <currency-input
                        :id="`ci-c${index}_${pIndex}_${mIndex}`"
                        v-model="month.corrected_budget"
                        class="form-control-sm budget-input"
                        :disabled="
                          !canEdit ||
                          (broadcastClosedMonths && broadcastClosedMonths.length > 0 && broadcastClosedMonths[mIndex - 1]
                            ? broadcastClosedMonths[mIndex - 1].is_closed
                            : false)
                        "
                        @setLastUsedInput="setLastUsedInput(`ci-c${index}_${pIndex}_${mIndex}`)"
                      />
                    </td>
                    <th>{{ countBudgetRowTotal(index, pIndex, 'corrected_budget') | spaceNumber }}</th>
                    <th>{{ getDeltaRowBudget(index, pIndex) | spaceNumber }}</th>
                    <td v-if="isLimitedBudgets">
                      <input
                        v-model.number="plType.budgetLimit"
                        class="form-control form-control-sm w-125px ml-auto"
                        type="number"
                        required
                        step="any"
                        min="0"
                        max="100"
                        data-automatic="input-mcb-b-limit"
                        :disabled="isClosed || !canEdit"
                      />
                    </td>
                  </tr>
                  <tr v-if="showEquals" :key="`ci-e${index}_${pIndex}`">
                    <td>
                      <span :class="setPlacementClass(plType.type ? plType.type.id : undefined)"></span>
                      <label class="s-w-100-fit pr-1">
                        {{ $t('equalsWGRP.equalsPrime') }}
                      </label>
                    </td>
                    <td v-for="(month, mIndex) in plType.months" :key="`ci-e${index}_${pIndex}_${mIndex}`">
                      <currency-input
                        :id="`ci-e${index}_${pIndex}_${mIndex}`"
                        v-model="month.prime_wgrp"
                        class="form-control-sm budget-input"
                        :disabled="
                          !canEdit ||
                          (broadcastClosedMonths && broadcastClosedMonths.length > 0 && broadcastClosedMonths[mIndex - 1]
                            ? broadcastClosedMonths[mIndex - 1].is_closed
                            : false)
                        "
                        @input="updateEqualsSums()"
                        @setLastUsedInput="setLastUsedInput(`ci-e${index}_${pIndex}_${mIndex}`)"
                      />
                    </td>
                    <th></th>
                    <th></th>
                    <td v-if="isLimitedBudgets"></td>
                  </tr>
                  <tr v-if="showEquals" :key="`ci-f${index}_${pIndex}`">
                    <td>
                      <span :class="setPlacementClass(plType.type ? plType.type.id : undefined)"></span>
                      <label class="s-w-100-fit pr-1">
                        {{ $t('equalsWGRP.equalsOffPrime') }}
                      </label>
                    </td>
                    <td v-for="(month, mIndex) in plType.months" :key="`ci-f${index}_${pIndex}_${mIndex}`">
                      <currency-input
                        :id="`ci-f${index}_${pIndex}_${mIndex}`"
                        v-model="month.off_prime_wgrp"
                        class="form-control-sm budget-input"
                        :disabled="
                          !canEdit ||
                          (broadcastClosedMonths && broadcastClosedMonths.length > 0 && broadcastClosedMonths[mIndex - 1]
                            ? broadcastClosedMonths[mIndex - 1].is_closed
                            : false)
                        "
                        @setLastUsedInput="setLastUsedInput(`ci-f${index}_${pIndex}_${mIndex}`)"
                        @input="updateEqualsSums()"
                      />
                    </td>
                    <th></th>
                    <th></th>
                    <td v-if="isLimitedBudgets"></td>
                  </tr>
                  <!-- PERCENT ROW UNDER EACH PLACEMENT TYPE -->
                  <tr :key="`${index}_${pIndex}-percent`" class="execution-percent">
                    <td>{{ $t('table.percentExecution') }}</td>
                    <td v-for="(month, mIndex) in plType.months" :key="`${index}_${pIndex}_${mIndex}-percent`">
                      {{ (((+month.corrected_budget || 0) / (+month.original_budget || 1)) * 100).toFixed(2) }}%
                    </td>
                    <td>{{ countBudgetPercentRowTotal(index, pIndex) }}%</td>
                    <td>{{ getDeltaPercentRowBudget(index, pIndex) }}%</td>
                    <th v-if="isLimitedBudgets"></th>
                  </tr>
                </template>
                <!-- GENERAL TOTAL IN CHANNEL -->
                <tr :key="`${index}-totals`" class="total-row">
                  <th>{{ $t('booking.total') }}</th>
                  <th v-for="(mn, mIndex) in months" :key="mIndex">{{ countBudgetColTotal(index, mIndex, 'corrected_budget') | spaceNumber }}</th>
                  <th>{{ countCornerSum(index, 'corrected_budget') | spaceNumber }}</th>
                  <th>{{ countCornerDeltaSum(index) | spaceNumber }}</th>
                  <th v-if="isLimitedBudgets"></th>
                </tr>
              </template>
            </template>

            <tr>
              <td :colspan="isLimitedBudgets ? 18 : 17"><h5 class="mt-3 text-left">wGRP</h5></td>
            </tr>

            <tr v-for="(sel, index) in channelsBudgets" :key="`${index}-wgrps`">
              <td>{{ sel.channel ? sel.channel.name : '' }}</td>
              <td></td>
              <td>wGRP</td>
              <td v-for="(month, mIndex) in sel.wgrpByMonths" :key="`${index}_${mIndex}-wgrp`">
                {{ (+month).toFixed(2) | spaceNumber }}
              </td>
              <th>{{ sel.wgrpGeneralSum | spaceNumber }}</th>
              <th></th>
              <th v-if="isLimitedBudgets"></th>
            </tr>
            <template v-if="showEquals">
              <tr>
                <td :colspan="isLimitedBudgets ? 18 : 17">
                  <h5 class="mt-3 text-left">{{ $t('equalsWGRP.equals') }}</h5>
                </td>
              </tr>

              <tr v-for="(sel, index) in channelsBudgets" :key="`${index}-wgrps-equals`">
                <td>{{ sel.channel ? sel.channel.name : '' }}</td>
                <td></td>
                <td>{{ $t('equalsWGRP.equals') }}</td>
                <td v-for="(month, mIndex) in sel.equalsByMonths" :key="`${index}_${mIndex}-wgrp-equals`">
                  {{ (+month).toFixed(2) | spaceNumber }}
                </td>
                <th>{{ sel.equalsGeneralSum | spaceNumber }}</th>
                <th></th>
                <th v-if="isLimitedBudgets"></th>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </form>
  </b-modal>
</template>

<script>
import { required, minValue, maxValue } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import spaceNumber from '@/filters/spaceNumber';
import CurrencyInput from '@/components/CurrencyInput';
import setPlacementClass from '@/mixins/setPlacementClass';

export default {
  name: 'ModalChannelsBudgets',
  components: { CurrencyInput },
  filters: { spaceNumber },
  mixins: [setPlacementClass],
  model: {
    prop: 'value',
    event: 'onSubmit',
  },
  props: {
    canEdit: { type: Boolean },
    isClosed: { type: Boolean },
    value: { type: Array, default: undefined },
    isLimitedBudgets: { type: Boolean },
  },
  data() {
    return {
      channelsBudgets: this.value,
      lastUsedInput: '',
      is_equals_enabled: false,
    };
  },
  validations() {
    const isLimitedBudgets = this.isLimitedBudgets
      ? {
          required,
          minValue: minValue(0),
          maxValue: maxValue(100),
        }
      : {};
    return {
      channelsBudgets: {
        $each: {
          prime: { minValue: minValue(0), maxValue: maxValue(100) },
          placementTypes: {
            $each: {
              type: { required },
              budgetLimit: isLimitedBudgets,
              months: {
                $each: {
                  original_budget: { minValue: minValue(0) },
                  corrected_budget: { minValue: minValue(0) },
                  prime_wgrp: { minValue: minValue(0) },
                  off_prime_wgrp: { minValue: minValue(0) },
                  original_prime_wgrp: { minValue: minValue(0) },
                  original_off_prime_wgrp: { minValue: minValue(0) },
                },
              },
            },
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      isLocale: 'isLocale',
      isThemeHeader: 'isTheme',
      channelsList: 'getChannelsList',
      placementTypeWithoutAll: 'getPlacementTypeWithoutAll',
      broadcastClosedMonths: 'getBroadcastClosedMonths',
      selectedSaleshouse: 'getSelectedSaleshouse',
      sh_settings: 'getSalehousesSettings',
      project: 'getModalEditProjects',
    }),
    showEquals() {
      return this.FEATURES.EQUALS && this.is_equals_enabled;
    },
    months() {
      return [
        this.$i18n.t('month.jan'),
        this.$i18n.t('month.feb'),
        this.$i18n.t('month.mar'),
        this.$i18n.t('month.apr'),
        this.$i18n.t('month.may'),
        this.$i18n.t('month.jun'),
        this.$i18n.t('month.jul'),
        this.$i18n.t('month.aug'),
        this.$i18n.t('month.sep'),
        this.$i18n.t('month.oct'),
        this.$i18n.t('month.nov'),
        this.$i18n.t('month.dec'),
      ];
    },
  },
  methods: {
    async handlePaste(e) {
      if (!(e.target instanceof HTMLInputElement)) return; // execute only while pasting in input field
      const elementId = e.target.id;
      if (!(elementId && /(ci-a|ci-c|ci-b|ci-d|ci-e|ci-f)\d{1,}_\d{1,}_\d{1,}/.test(elementId))) return;
      e.preventDefault();
      let text;
      if (navigator?.clipboard?.readText) {
        text = await navigator.clipboard.readText();
      } else {
        text = e.clipboardData.getData('text/plain');
      }
      const splittedText = text.split(/\t|\n/).map((e) => +e.replace(',', '.'));
      const ids = elementId
        .slice(4)
        .split('_')
        .map((e) => +e);
      const channelId = ids[0];
      const placementTypeId = ids[1];
      const monthId = ids[2];
      let valueIndex = 0;
      //paste into approved table or corrected table
      let letter = elementId.slice(3, 4);
      const budget_types = {
        a: 'original_budget',
        c: 'corrected_budget',
        e: 'prime_wgrp',
        f: 'off_prime_wgrp',
        b: 'original_prime_wgrp',
        d: 'original_off_prime_wgrp',
      };
      const budget_type = budget_types[letter];
      if (!budget_type) return;
      for (let index in this.channelsBudgets[channelId].placementTypes[placementTypeId].months) {
        if (index < monthId) continue;
        if (splittedText[valueIndex] >= 0 && !Number.isNaN(splittedText[valueIndex])) {
          this.channelsBudgets[channelId].placementTypes[placementTypeId].months[index][budget_type] = splittedText[valueIndex];

          if (budget_type === 'original_budget' && !(this.broadcastClosedMonths && this.broadcastClosedMonths[index - 1]?.is_closed)) {
            this.channelsBudgets[channelId].placementTypes[placementTypeId].months[index].corrected_budget = splittedText[valueIndex];
          }
          if (budget_type === 'original_prime_wgrp' && !(this.broadcastClosedMonths && this.broadcastClosedMonths[index - 1]?.is_closed)) {
            this.channelsBudgets[channelId].placementTypes[placementTypeId].months[index].prime_wgrp = splittedText[valueIndex];
          }
          if (budget_type === 'original_off_prime_wgrp' && !(this.broadcastClosedMonths && this.broadcastClosedMonths[index - 1]?.is_closed)) {
            this.channelsBudgets[channelId].placementTypes[placementTypeId].months[index].off_prime_wgrp = splittedText[valueIndex];
          }
        }
        valueIndex++;
      }

      if (this.showEquals && budget_type != 'a' && budget_type != 'c') {
        this.updateEqualsSums();
      }
    },

    loadData() {
      if (this.value) {
        if (this.FEATURES.EQUALS) {
          if (
            this.project.year_id &&
            !(this.sh_settings && this.sh_settings[0]?.year_id == this.project.year_id && this.selectedSaleshouse.id == this.sh_settings.saleshouse_id)
          )
            this.$store.dispatch('GET_SALEHOUSES_SETTINGS', {
              //    id: 123,
              'filter[saleshouse_id]': this.selectedSaleshouse.id,
              'filter[year_id]': this.project.year_id,
            });
          this.is_equals_enabled = this.sh_settings[0]?.is_equals_enabled ?? false;
        } else {
          this.is_equals_enabled = false;
        }

        window.addEventListener('focus', this.restoreFocusOnInput);
        document.addEventListener('paste', this.handlePaste);
        this.channelsBudgets = this.value;
        if (this.showEquals) {
          this.channelsBudgets.forEach((c) => {
            let wgrpGeneralSum = 0;
            c.wgrpByMonths = {};
            c.placementTypes.forEach((pt) => {
              for (let m in pt.months) {
                wgrpGeneralSum += (+pt.months[m].pure_off_prime_wgrp || 0) + (+pt.months[m].pure_prime_wgrp || 0);
                c.wgrpByMonths[m] = (c.wgrpByMonths[m] || 0) + (+pt.months[m].pure_off_prime_wgrp || 0) + (+pt.months[m].pure_prime_wgrp || 0);
              }
            });
            c.wgrpGeneralSum = wgrpGeneralSum.toFixed(2);
          });
          this.updateEqualsSums();
        } else {
          this.channelsBudgets.forEach((c) => {
            let wgrpGeneralSum = 0;
            c.wgrpByMonths = {};
            c.placementTypes.forEach((pt) => {
              for (let m in pt.months) {
                wgrpGeneralSum += (+pt.months[m].off_prime_wgrp || 0) + (+pt.months[m].prime_wgrp || 0);
                c.wgrpByMonths[m] = (c.wgrpByMonths[m] || 0) + (+pt.months[m].off_prime_wgrp || 0) + (+pt.months[m].prime_wgrp || 0);
              }
            });
            c.wgrpGeneralSum = wgrpGeneralSum.toFixed(2);
          })
        }
      }
    },
    addTypeRow(sel) {
      sel.hiddenRows = false;
      sel.hiddenRowsCCP = false;
      const months = {};
      for (let i = 1; i < 13; i++) {
        months[i] = {
          corrected_budget: '',
          original_budget: '',
          original_prime_wgrp: '',
          original_off_prime_wgrp: '',
          prime_wgrp: '',
          off_prime_wgrp: '',
        };
      }
      sel.placementTypes.push({
        type: '',
        months,
      });
    },
    removeTypeRow(index, data) {
      this.channelsBudgets[index].placementTypes.splice(data, 1);
    },

    checkIfValidThenEnter() {
      if (this.$v.$invalid) return;
      this.$bvModal.hide('project-channels-budgets');
    },

    countBudgetRowTotal(index, plType, field) {
      const obj = this.channelsBudgets[index]?.placementTypes[plType]?.months;
      let sum = 0;
      if (obj) {
        if (field === 'original_budget')
          sum = Object.values(obj)
            .reduce((total, current) => total + (+current.original_budget || 0), 0)
            .toFixed(2);
        else if (field === 'corrected_budget')
          sum = Object.values(obj)
            .reduce((total, current) => total + (+current.corrected_budget || 0), 0)
            .toFixed(2);
      }
      return sum || '';
    },

    countBudgetColTotal(index, mIndex, field) {
      let sum = 0;
      if (field === 'original_budget') this.channelsBudgets[index]?.placementTypes?.forEach((pl) => (sum += +pl?.months[mIndex + 1]?.original_budget || 0));
      else if (field === 'corrected_budget')
        this.channelsBudgets[index]?.placementTypes?.forEach((pl) => (sum += +pl?.months[mIndex + 1]?.corrected_budget || 0));
      return sum.toFixed(2) ?? '';
    },

    getDeltaRowBudget(index, pIndex) {
      return ((this.countBudgetRowTotal(index, pIndex, 'corrected_budget') || 0) - this.countBudgetRowTotal(index, pIndex, 'original_budget')).toFixed(2) || 0;
    },

    countCornerSum(index, field) {
      let sum = 0;
      this.channelsBudgets[index]?.placementTypes.forEach((pt) => {
        for (let m in pt.months) {
          sum += field === 'original_budget' ? +pt.months[m].original_budget || 0 : +pt.months[m].corrected_budget || 0;
        }
      });
      return sum.toFixed(2);
    },

    countCornerDeltaSum(index) {
      return ((this.countCornerSum(index, 'corrected_budget') || 0) - (this.countCornerSum(index, 'original_budget') || 0)).toFixed(2);
    },

    countBudgetPercentRowTotal(index, pIndex) {
      return (
        ((+this.countBudgetRowTotal(index, pIndex, 'corrected_budget') || 0) / (+this.countBudgetRowTotal(index, pIndex, 'original_budget') || 1)) *
        100
      ).toFixed(2);
    },

    getDeltaPercentRowBudget(index, pIndex) {
      return (((+this.getDeltaRowBudget(index, pIndex) || 0) / (+this.countBudgetRowTotal(index, pIndex, 'original_budget') || 1)) * 100).toFixed(2);
    },

    toggleVisibility(sel, table) {
      //for approvedCP table
      if (table === 'hiddenRows') sel.hiddenRows = !sel.hiddenRows;
      else if (table === 'hiddenRowsCCP') sel.hiddenRowsCCP = !sel.hiddenRowsCCP; //for current CP table
    },

    getChannelRowSpan(index) {
      if (this.channelsBudgets[index].hiddenRows) return 1;
      else return this.channelsBudgets[index]?.placementTypes?.length * (this.showEquals ? 3 : 1) + 2;
    },

    writeInCurrentCP(month, mIndex) {
      // do not rewrite Current CP cell data if its month is closed
      if (!(this.broadcastClosedMonths && this.broadcastClosedMonths[mIndex - 1]?.is_closed)) month.corrected_budget = month.original_budget;
    },
    writeInCurrentCP_EP(month, mIndex) {
      if (!(this.broadcastClosedMonths && this.broadcastClosedMonths[mIndex - 1]?.is_closed)) {
        month.prime_wgrp = month.original_prime_wgrp;
        this.updateEqualsSums();
      }
    },
    writeInCurrentCP_EOP(month, mIndex) {
      if (!(this.broadcastClosedMonths && this.broadcastClosedMonths[mIndex - 1]?.is_closed)) {
        month.off_prime_wgrp = month.original_off_prime_wgrp;
        this.updateEqualsSums();
      }
    },

    setLastUsedInput(prop) {
      this.lastUsedInput = prop;
    },

    restoreFocusOnInput() {
      if (this.lastUsedInput) {
        const id = this.lastUsedInput;
        setTimeout(() => {
          document.getElementById(id)?.focus();
        }, 10);
      }
    },

    closedModal() {
      this.lastUsedInput = '';
      window.removeEventListener('focus', this.restoreFocusOnInput);
      document.removeEventListener('paste', this.handlePaste);
    },

    updateEqualsSums() {
      this.channelsBudgets.forEach((c) => {
        let sum = 0;
        c.equalsByMonths = {};
        c.placementTypes.forEach((pt) => {
          for (let m in pt.months) {
            sum += (+pt.months[m].off_prime_wgrp || 0) + (+pt.months[m].prime_wgrp || 0);
            c.equalsByMonths[m] = (+pt.months[m].off_prime_wgrp || 0) + (+pt.months[m].prime_wgrp || 0);
          }
        });
        c.equalsGeneralSum = sum.toFixed(2);
      });
    },
  },
};
</script>

<style lang="sass" src="../../assets/sass/mediaPlanPlacementTypes.sass"></style>

<style lang="sass">
#project-channels-budgets > .modal-dialog
  max-width: calc(100% - 16px)
  #approved-budgets-table,
  #corrected-budgets-table
    thead
      th
        min-width: 101px
        &[colspan="2"]
          min-width: 125px
  table
    td
      padding: 0 0 0 1px
    tbody th
      padding: 0 0 0 8px

    td:not([rowspan]),
    tbody th
      vertical-align: middle
      text-align: right
    .first-colspan-td
      display: none

    .s-w-100
      width: 100px !important
      max-width: 100px !important
      min-width: 100px !important

    .s-w-100-fit
      width: 100px !important
      max-width: 100px !important
      min-width: 100px !important

  .budget-input
    width: 100px
    text-align: right
    border-radius: 0

  .budget-input::-webkit-outer-spin-button,
  .budget-input::-webkit-inner-spin-button,
  .form-control-prime::-webkit-outer-spin-button,
  .form-control-prime::-webkit-inner-spin-button
    -webkit-appearance: none
    margin: 0

  /* Firefox */
  .budget-input[type=number],
  .form-control-prime[type=number]
    -moz-appearance: textfield

  button[data-automatic="add-type-btn"]
    font-size: 12px

  .execution-percent
    background-color: hsl(240,6%,97%)

  .total-row
    background-color: hsl(240,6%,90%)

  span.media-plan-pt
    display: inline-block
    height: 28px
    margin: 0
    vertical-align: middle

[data-color-scheme="dark"] #project-channels-budgets > .modal-dialog
  .execution-percent
    background-color: hsl(221,14%,27%)
  .total-row
    background-color: hsl(221,14%,33%)
    //color: hsl(220,14%,90%) !important

  button[type="button"].btn-transparent
    color: hsl(220,14%,90%) !important
</style>
